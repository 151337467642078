import "../styles/projectcontent.css";
import mazeimage from "../assets/maze.png";
import nsimage from "../assets/naturalselection.png";
import wsimage from "../assets/survivalgame.png";
import gameoflifeimage from "../assets/gameoflife.png";
import checkersimage from "../assets/checkers.png";
import AIClassifier from "../assets/ai-classifier.png";
import userAuthentication from "../assets/user-authetication.png";

function Projectcontent({ e }) {
  if (e === "maze") {
    return (
      <>
        <h2>Maze generator & solver</h2>

        <a
          href="https://maze.caledoniaprogramming.co.uk/"
          target="_blank"
          rel="noreferrer"
        >
          <button>View project webpage</button>
        </a>

        <a href="https://maze.caledoniaprogramming.co.uk/">
          <img className="logo" alt="maze" src={mazeimage} />
        </a>

        <div className="list">
          <li>ReactJS</li>
          <li>Algorithmic maze generation</li>
          <li>Optimised pathfinding</li>
          <li>Clean UI</li>
          <li>Reponsive styling</li>
        </div>

        <div className="projectinfo">
          <p>
            I created a program which can generate - and then solve - random
            mazes.
            <br />
            <br /> I have used "Prim's Algorithm" to create the mazes in a grid
            format. Once the grid has been filled, start and end nodes are
            selected.
            <br />
            <br /> My pathfinding function is based on the A* Algorithm
            principles, optimising the number of cells needing to be checked.
          </p>
        </div>
      </>
    );
  }

  if (e === "naturalselection") {
    return (
      <>
        <h2>Natural Selection Simulation</h2>

        <a
          href="https://naturalselection.caledoniaprogramming.co.uk/"
          target="_blank"
          rel="noreferrer"
        >
          <button>View project webpage</button>
        </a>

        <a href="https://naturalselection.caledoniaprogramming.co.uk/">
          <img className="logo" alt="naturalselection" src={nsimage} />
        </a>

        <div className="list">
          <li>Basic machine learning</li>
          <li>Simulation runs using React Hooks</li>
          <li>Interactive interface</li>
          <li>Responsive styling</li>
        </div>

        <div className="projectinfo">
          <p>
            I created an application with a user-centred, interactive interface
            that uses basic machine learning and vanilla React Hooks to simulate
            evolution by natural selection
            <br />
            <br /> The ‘Information’ button within the application brings the
            simulation to life by explaining how each generation of colourful
            entities helps to create a gene pool that will increase the
            following generation’s chances of survival.
          </p>
        </div>
      </>
    );
  }

  if (e === "survival") {
    return (
      <>
        <h2>Winter Survival Game</h2>

        <a
          href="https://wintersurvival.caledoniaprogramming.co.uk/"
          target="_blank"
          rel="noreferrer"
        >
          <button>View project webpage</button>
        </a>

        <a href="https://wintersurvival.caledoniaprogramming.co.uk/">
          <img className="logo" alt="wintersurvival" src={wsimage} />
        </a>

        <div className="list">
          <li>ReactJS</li>
          <li>Redux state management</li>
          <li>CSS Grid</li>
          <li>Chakra-Ui for styling</li>
          <li>Dynamic CSS</li>
        </div>

        <div className="projectinfo">
          <p>
            I created an (unfinished) single player survival game using Redux
            state management to control the gamestate.
            <br />
            <br /> The user must balance stats and make important decisions to
            reach the endgame.
          </p>
        </div>
      </>
    );
  }

  if (e === "gameoflife") {
    return (
      <>
        <h2>Conway's Game of Life</h2>

        <a
          href="http://gameoflife.caledoniaprogramming.co.uk/"
          target="_blank"
          rel="noreferrer"
        >
          <button>View project webpage</button>
        </a>

        <a href="http://gameoflife.caledoniaprogramming.co.uk/">
          <img className="logo" alt="gameoflife" src={gameoflifeimage} />
        </a>

        <div className="list">
          <li>ReactJS</li>
          <li>Celluar Automaton Example </li>
          <li>Safe mutation of object arrays</li>
          <li>Understanding of the call stack</li>
          <li>User manuipulation of simulation</li>
        </div>

        <div className="projectinfo">
          <p>
            I created a version of Conway's "Game of Life".
            <br /> <br /> I designed a UI allows the user to interact
            dynamically with the simulation. The framerate can be changed, the
            simulation paused, and the grid cleared or randomised. The user can
            also click on the grid to toggle the state of individual cells.
            <br /> <br /> For example, the simulation can be paused, a pattern
            drawn by the user, and then resumed to see how the simulation
            responds.
          </p>
        </div>
      </>
    );
  }

  if (e === "checkers") {
    return (
      <>
        <h2>Checkers</h2>

        <a
          href="https://checkers.caledoniaprogramming.co.uk/"
          target="_blank"
          rel="noreferrer"
        >
          <button>View project webpage</button>
        </a>

        <a href="https://checkers.caledoniaprogramming.co.uk/">
          <img className="logo" alt="checkers" src={checkersimage} />
        </a>

        <div className="list">
          <li>ReactJS</li>
          <li>Array creation / mutation</li>
          <li>Clear UI</li>
        </div>

        <div className="projectinfo">
          <p>
            Checkers may be a simple game, but it turns out programming a
            checkers board to apply the rules of the game is not so simple!
            <br />
            <br /> Through state control and the correct use and manipulation of
            data arrays, I succeeded in producing a functional game of Checkers
            (as validated by substantial user testing, conducted by my five year
            old son!).
          </p>
        </div>
      </>
    );
  }

  if (e === "ai-classifier") {
    return (
      <>
        <h2>AI Celestial Classifier</h2>

        <a
          href="https://ai-celestial-classifier.caledoniaprogramming.co.uk//"
          target="_blank"
          rel="noreferrer"
        >
          <button>View project webpage</button>
        </a>

        <a href="https://ai-celestial-classifier.caledoniaprogramming.co.uk//">
          <img className="logo" alt="ai-classifier" src={AIClassifier} />
        </a>

        <div className="list">
          <li>Use of Brain.js neural network</li>
          <li>User engagement with neural network</li>
          <li>Procedurally generated pure-CSS background</li>
          <li>Data configuration</li>
        </div>

        <div className="projectinfo">
          <p>
            I created a programme which uses an AI network (“Brain.js”) to try
            to classify various celestial objects as a “Star”, “Galaxy” or
            “Quasar”. I designed it to be interactive for users: by adjusting
            and testing four neural network settings (training iterations, error
            threshold, learning rate and momentum), the user tries to identify
            which settings will allow the Neural Network to achieve the highest
            percentage of correct classifications. <br />
            <br />
            When the user clicks "Test Data", the Neural Network will train
            using data from 1,000 celestial objects. It will then "test itself"
            using the settings chosen by the user, by guessing the class of
            1,000 additional celestial objects, recording it's accuracy as it
            goes. <br />
            <br />I also programmed the spacial themed background for my AI
            celestial classifier using pure CSS.
          </p>
        </div>
      </>
    );
  }

  if (e === "user-authentication") {
    return (
      <>
        <h2>Full-Stack Forum</h2>

        <a
          href="https://www.youtube.com/watch?v=mLrr44Nef9Q"
          target="_blank"
          rel="noreferrer"
        >
          <button>View project demo</button>
        </a>

        <a href="https://www.youtube.com/watch?v=mLrr44Nef9Qwww.youtube.com">
          <img
            className="logo"
            alt="user-authentication"
            src={userAuthentication}
          />
        </a>

        <div className="list">
          <li>User authentication</li>
          <li>Cookie Use</li>
          <li>PostgreSQL-Express-ReactJS-NodeJS</li>
          <li>C.R.U.D. Functionality</li>
          <li>Axios API for data fetching</li>
          <li>React-Router</li>
          <li>Security with Helmet and Bcrypt</li>
          <li>pgAdmin for database control</li>
        </div>

        <div className="projectinfo">
          <p>
            I created a website where users can create an account, and securely
            interact with a forum. <br />
            <br />
            The forum consists of an "Essentially Bad List", where users can add
            their dislikes, and then upvote other additions.
          </p>
        </div>
      </>
    );
  }
}

export default Projectcontent;
