import "../styles/myskills.css";

function MySkills() {
  return (
    <div className="skills-container">
      <div className="programming-skills">
        <h2>PROGRAMMING SKILLS</h2>
        <p>
          {" "}
          As demonstrated by my projects, my experience in building front-end
          applications includes:{" "}
        </p>
        <li> UI/UX with ReactJS (HTML, CSS, and Javascript ES6)</li>
        <li> Version control with Git</li>
        <li> CI/CD pipelines</li>
        <li> Utilising testing frameworks</li>
        <li> Building micro-frontend components </li>
        <li> Web interface for embedded devices</li>
        <li> SQL databases such as PostgreSQL </li>
        <li> Implenting RESTful APIs</li>
        <li> Full stack development (e.g. PostgreSQL–Express–React–Node) </li>
        <li> Experience with Linux servers and tooling</li>
        <li> Cloud providers such as AWS</li>
        <li> Some experience with Python and Java</li>
        <li> Use of neural networks and machine learning</li>
      </div>

      <div className="programming-skills">
        <h2>PERSONAL QUALITIES</h2>
        <li> Highly self-motivated</li>
        <li> Strong time management skills</li>
        <li> Fast and enthusiastic learner</li>
        <li> Adaptable to new challenges</li>
        <li> Creative problem solver </li>
        <li> High technical aptitude</li>
        <li> Excellent written and verbal communication</li>
      </div>
    </div>
  );
}

export default MySkills;
