import Projectcontent from "./projectcontent";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import "../styles/myprojects.css";
import { useState } from "react";

function Myprojects({ projectsexpanded }) {
  let [projects, setProjects] = useState([
    "survival",
    "ai-classifier",
    "user-authentication",
    "maze",
    "naturalselection",
    "gameoflife",
    "checkers",
  ]);
  let [animationtrigger, setAnimationtigger] = useState("0s");
  let [animating, setAnimating] = useState(false);

  function Displayprojects() {
    if (projectsexpanded) {
      return projects.map((e) => {
        return (
          <div
            className="projectbox"
            key={e}
            style={{ animationDuration: `${animationtrigger}` }}
          >
            <Projectcontent e={e} />
          </div>
        );
      });
    }
  }

  function Moveright() {
    setAnimating(true);
    setAnimationtigger("1s");
    setTimeout(() => {
      setAnimationtigger("0s");
      let newlist = [...projects];
      let move = newlist.shift();
      newlist.push(move);
      setProjects(newlist);
      setAnimating(false);
    }, 950);
  }

  return (
    <>
      <div className="projectscontainer">
        <Displayprojects />
      </div>
      <div className="nextbutton">
        <button disabled={animating} onClick={() => Moveright()}>
          Next
          <ArrowForwardIcon boxSize={30} />
        </button>
      </div>
    </>
  );
}

export default Myprojects;
