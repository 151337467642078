import "../styles/myexperience.css";

function Myexperience() {
  return (
    <div className="experience-container">
      <div className="personal-qualities">
        <h2>EDUCATION</h2>
        <p style={{ fontWeight: "bold" }}>MSc by Research </p>
        <i> University of Edinburgh (2014-2016)</i>
        <p> Postgraduate Degree</p>
        <br />

        <p style={{ fontWeight: "bold" }}>MTh (Hons) </p>
        <i> University of St Andrews (2010-2014)</i>
        <p> Undergraduate degree, 2:1</p>
        <br />

        <p style={{ fontWeight: "bold" }}>Secondary Education </p>
        <p>
          Advanced Higher Music (B) <br />
          Higher Mathematics (A) <br />
          Higher Physics (A) <br />
          Higher English (A) <br />
          Higher History (A) <br />
          Higher Modern Studies (A) <br />
        </p>

        <p>
          Standard Grades <br />6 x (A), 2 x (B)
        </p>

        <p style={{ fontWeight: "bold" }}>Primary Education </p>
        <p>
          • Excellent at fetching the football when it got kicked out of bounds{" "}
          <br />• Approx. 100 gold stars and stickers
        </p>
      </div>

      <div>
        <h2>PROFESSIONAL EXPERIENCE</h2>
        <h2> Employment in the charity sector</h2>
        <i> Church of Scotland (2015 – present, full-time)</i>
        <br />
        <br />
        <li>
          {" "}
          Independent working: managing a registered charity, staff, teams of
          volunteers, budgets, and buildings.{" "}
        </li>
        <li>
          {" "}
          Project management: large audio visual installation, building work,
          fundraising, community initiatives, and heritage management.{" "}
        </li>
        <li>
          {" "}
          Communication: weekly public speaking to 100+ people, regular formal
          written work, and managing various teams of volunteers.{" "}
        </li>
        <li>
          {" "}
          Technical responsibilities: transferring activities online during
          lockdown, production of creative video content, use of cloud software
          for collaborative working, and social media management.{" "}
        </li>

        <h2>RAF Reserves</h2>
        <i>
          {" "}
          East of Scotland University Air Squadron (2010 – 2015, part time)
        </i>
        <br />
        <br />
        <li>
          {" "}
          Aviation: solo aviation and advanced modules such as navigation,
          aerobatics and formation flying. Mastered the use of flight software
          and instruments, and adhered to strict quality standards and
          communication protocols. Awarded highest grade by RAF’s pilot
          assessment unit (“Exceptional”) and earned my Private Pilot’s License.
        </li>
        <li>
          {" "}
          Fostering team culture and co-operation: commissioned as military
          officer for two years. Led 120 reservists during a 10-day field
          exercise.
        </li>
        <li>
          {" "}
          Resilience: through adventurous personal development, ground training,
          fieldcraft, leadership training, sports, and charity work I learned to
          be effective in high pressure and unfamiliar circumstances.{" "}
        </li>
      </div>
    </div>
  );
}

export default Myexperience;
