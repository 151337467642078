import { useEffect } from "react";
import "../styles/background.css";
import { useState } from "react";
import UpdateCells from "../utils/updatecells";
import CreateCells from "../utils/createcells";

function Background() {
  let gridsize;
  let screenwidth = window.innerWidth;
  let screenheight = window.innerHeight;
  let gridheight;
  let gridwidth;

  if (screenheight > screenwidth) {
    gridsize = screenwidth / 20 + 1;
    gridwidth = screenwidth / gridsize;
    gridheight = screenheight / gridsize;
  } else {
    gridsize = screenheight / 20 + 1;
    gridwidth = screenheight / gridsize;
    gridheight = screenwidth / gridsize;
  }

  let initialvalues = CreateCells({ gridwidth, gridheight });

  let [grid, setGrid] = useState(initialvalues);

  useEffect(() => {
    const interval = setInterval(() => {
      UpdateCells({ grid, setGrid });
    }, 150);
    return () => clearInterval(interval);
  }, [grid]);

  function Mouseover(e) {
    if (e !== undefined) {
      console.log("mouseover");
      let newgrid = [...grid];
      newgrid[e.target.id][1] = 255;
      setGrid(newgrid);
    }
  }

  return (
    <div className="backgroundcontainer">
      {grid.map((e) => (
        <div
          key={e[0]}
          id={e[0]}
          className="background-cell"
          onMouseOver={Mouseover}
          style={{
            width: `${gridsize}px`,
            height: `${gridsize}px`,
            background: `rgb(0, ${e[1]}, ${e[1]})`,
            opacity: 0.15,
          }}
        >
          {e[2]}
        </div>
      ))}
    </div>
  );
}

export default Background;
