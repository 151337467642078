import "./styles/App.css";
import Navbar from "./views/navbar";
import Contentselection from "./views/contentselection";
import Background from "./views/background";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Background />
      <Contentselection />
    </div>
  );
}

export default App;
