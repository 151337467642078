import "../styles/navbar.css";
import { SocialIcon } from "react-social-icons";
import callogo from "../assets/callogo.png";

function Navbar() {
  return (
    <div className="nav">
      <img alt="logo" className="navheading" src={callogo}></img>
      <SocialIcon url="https://github.com/SeorasOrr" bgColor="white" />
    </div>
  );
}

export default Navbar;
