import { useState } from "react";
import "../styles/contentselection.css";
import Myprojects from "./myprojects";
import Myexperience from "./myexperience";
import MySkills from "./myskills";

function Contentselection() {
  const regularboxwidth = "30vw";
  const expandedboxwidth = "96vw";
  const regularboxheight = "50vh";
  const expandedboxheight = "75vh";

  let [storyexpanded, setStoryexpanded] = useState(false);
  let [projectsexpanded, setProjectsexpanded] = useState(false);
  let [skillsexpanded, setSkillsexpanded] = useState(false);
  let [triggerstoryanimation, setTriggerstoryanimation] = useState(false);
  let [triggerprojectanimation, setTriggerprojectanimation] = useState(false);
  let [triggerskillsanimation, setTriggerskillsanimation] = useState(false);
  let [collapseprojectaniamtion, setCollapseprojectanimation] = useState(false);
  let [collapsestoryanimation, setCollapsestoryanimation] = useState(false);
  let [collapseskillsanimation, setCollapseskillsanimation] = useState(false);

  function Expandstory() {
    setTriggerstoryanimation(true);
    setTimeout(() => {
      setStoryexpanded(true);
    }, 950);
  }

  function Collapsestory() {
    setTriggerstoryanimation(false);
    setCollapsestoryanimation(true);
    setStoryexpanded(false);
    setTimeout(() => {
      setCollapsestoryanimation(false);
    }, 950);
  }

  function Expandprojects() {
    setTriggerprojectanimation(true);
    setTimeout(() => {
      setProjectsexpanded(true);
    }, 950);
  }

  function Collapseprojects() {
    setTriggerprojectanimation(false);
    setCollapseprojectanimation(true);
    setProjectsexpanded(false);
    setTimeout(() => {
      setCollapseprojectanimation(false);
    }, 950);
  }

  function Expandskills() {
    setTriggerskillsanimation(true);
    setTimeout(() => {
      setSkillsexpanded(true);
    }, 950);
  }

  function Collapseskills() {
    setTriggerskillsanimation(false);
    setCollapseskillsanimation(true);
    setSkillsexpanded(false);
    setTimeout(() => {
      setCollapseskillsanimation(false);
    }, 950);
  }

  function Experiencecontent() {
    if (storyexpanded) {
      return (
        <>
          <Myexperience />
          <div className="selectionbuttons">
            <button onClick={() => Collapsestory()}>Close</button>
          </div>
        </>
      );
    } else {
      if (!triggerstoryanimation) {
        return (
          <>
            <div className="summaryline">
              My education and professional experience
            </div>
            <button onClick={() => Expandstory()}>Learn more</button>
          </>
        );
      }
    }
  }

  function Projectcontent() {
    if (projectsexpanded) {
      return (
        <>
          <Myprojects projectsexpanded={projectsexpanded} />
          <div className="selectionbuttons">
            <button onClick={() => Collapseprojects()}>Close</button>
          </div>
        </>
      );
    } else {
      if (!triggerprojectanimation) {
        return (
          <>
            <div className="summaryline">
              Explore a portfolio of coding projects
            </div>
            <button onClick={() => Expandprojects()}>Learn more</button>
          </>
        );
      }
    }
  }

  function Skillscontent() {
    if (skillsexpanded) {
      return (
        <>
          <MySkills />
          <div className="selectionbuttons">
            <button onClick={() => Collapseskills()}>Close</button>
          </div>
        </>
      );
    } else {
      if (!triggerskillsanimation) {
        return (
          <>
            <div className="summaryline">
              Why I would make a great <br /> addition to your team
            </div>
            <button onClick={() => Expandskills()}>Learn more</button>
          </>
        );
      }
    }
  }

  return (
    <div className="container">
      <div
        className="mainbox"
        style={{
          width: skillsexpanded ? expandedboxwidth : regularboxwidth,
          height: skillsexpanded ? expandedboxheight : regularboxheight,
          display:
            triggerprojectanimation || triggerstoryanimation ? "none" : "block",
          animation: triggerskillsanimation
            ? "fillscreen 1s"
            : collapseskillsanimation
            ? "collapsescreen 1s"
            : "",
          left: collapsestoryanimation ? "2vw" : skillsexpanded ? "2vw" : "2vw",
        }}
      >
        <div className="heading">Skills</div>
        <Skillscontent />
      </div>

      <div
        className="mainbox"
        style={{
          width: projectsexpanded ? expandedboxwidth : regularboxwidth,
          height: projectsexpanded ? expandedboxheight : regularboxheight,
          display:
            triggerskillsanimation || triggerstoryanimation ? "none" : "block",
          animation: triggerprojectanimation
            ? "fillscreen 1s"
            : collapseprojectaniamtion
            ? "collapsescreen 1s"
            : "",
          left: collapseprojectaniamtion
            ? "35vw"
            : projectsexpanded
            ? "2vw"
            : "35vw",
        }}
      >
        <div className="heading">Projects</div>
        <Projectcontent />
      </div>

      <div
        className="mainbox"
        style={{
          width: storyexpanded ? expandedboxwidth : regularboxwidth,
          height: storyexpanded ? expandedboxheight : regularboxheight,
          display:
            triggerskillsanimation || triggerprojectanimation
              ? "none"
              : "block",
          animation: triggerstoryanimation
            ? "fillscreen 1s"
            : collapsestoryanimation
            ? "collapsescreen 1s"
            : "",
          left: collapseskillsanimation
            ? "68vw"
            : storyexpanded
            ? "2vw"
            : "68vw",
        }}
      >
        <div className="heading"> {!storyexpanded ? "Experience" : ""}</div>
        <Experiencecontent />
      </div>
    </div>
  );
}

export default Contentselection;
