function UpdateCells({ grid, setGrid }) {
let newgrid = [...grid];
  for (let i = 0; i < newgrid.length; i++) {
    if (newgrid[i][1] <= 0) {
      newgrid[i][1] = Math.floor(Math.random() * 200);
    } else {
      let prevvalue = newgrid[i][1];
      newgrid[i][1] = prevvalue - 3;
    }
  }
  setGrid(newgrid);
}

export default UpdateCells;
