function CreateCells({ gridwidth, gridheight }) {
  let grid = new Array(Math.floor(gridwidth * gridheight));
  let randomsymbols = ["{ }", "[ ]", "< >", "( )"];

  for (let i = 0; i < grid.length; i++) {
    let randomsymbolref = Math.floor(Math.random() * randomsymbols.length);
    let colourvalue = Math.floor(Math.random() * 200);
    grid[i] = [i, colourvalue, randomsymbols[randomsymbolref]];
  }
  return grid;
}

export default CreateCells;
